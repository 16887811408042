// import CheckList from "@editorjs/checklist";
// import Code from "@editorjs/code";
// import Delimiter from "@editorjs/delimiter";
// import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
// import InlineCode from "@editorjs/inline-code";
// import LinkTool from "@editorjs/link";
// import List from "@editorjs/list";
// import Marker from "@editorjs/marker";
// import Paragraph from "@editorjs/paragraph";
// import Quote from "@editorjs/quote";
// import Raw from "@editorjs/raw";
// import Table from "@editorjs/table";
// import Warning from "@editorjs/warning";
import YoutubeEmbed from "editorjs-youtube-embed";
import uploadImageByFile from "../image/post-image-by-image";
import { PostImageByFileResponseSchema } from "../types/image";

export const tools = {
  header: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    class: Header,
    inlineToolbar: ["link"],
  },
  // list: {
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   class: List,
  //   inlineToolbar: true,
  // },
  // paragraph: {
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   class: Paragraph,
  //   inlineToolbar: true,
  // },
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // embed: Embed,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // table: Table,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // warning: Warning,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // code: Code,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // linkTool: LinkTool,
  image: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    class: ImageTool,
    config: {
      uploader: {
        uploadByFile(file: File): Promise<PostImageByFileResponseSchema> {
          const MAX_FILE_SIZE = 5 * 1024 * 1024;

          if (file.size > MAX_FILE_SIZE) {
            // eslint-disable-next-line no-alert
            alert("File size exceeds the 5MB limit");
            return Promise.reject(new Error("File size exceeds the 5MB limit"));
          }

          return uploadImageByFile(file).then((result) => {
            return {
              success: 1,
              file: {
                url: result.url,
              },
            };
          });
        },
      },
    },
  },
  youtubeEmbed: YoutubeEmbed,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // raw: Raw,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // quote: Quote,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // marker: Marker,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // checklist: CheckList,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // delimiter: Delimiter,
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // inlineCode: InlineCode,
};
