import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { CountryPath } from "src/entities/CountryPath";
import uploadImageByFile from "src/libs/image/post-image-by-image";
import { PostImageByFileResponseSchemaV2 } from "src/libs/types/image";
import { usePut } from "./usePut";

interface Props {
  input: {
    publisherId: string;
    country: CountryPath;
    acceptedFiles: File[];
    name: string;
    description: string;
    webUrl: string;
    phoneNumber: string;
    headquarters: string;
    founded: number;
    logoUrl: string;
    twitter: string;
    facebook: string;
    tiktok: string;
    youtube: string;
    instagram: string;
    linkedin: string;
  };
  functions: {
    handleBackDropOpen: () => void;
    handleBackdropClose: () => void;
  };
}

// V1を剥がし切ったタイミングで
export const useUpdateInfoV2 = ({ input, functions }: Props) => {
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Put publisher info
   */
  const { put } = usePut({
    url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/publisher`,
    onError: () => {
      enqueueSnackbar(t("hooks.edit_failed"), { variant: "error" });
    },
    onSuccess: () => {
      enqueueSnackbar(t("hooks.successfully_updated_company_information"), {
        variant: "success",
      });
    },
    onStart: () => {
      functions.handleBackDropOpen();
    },
    onEnd: () => {
      functions.handleBackdropClose();
    },
  });

  const updateInfo = async () => {
    try {
      if (input.acceptedFiles.length > 0) {
        const data: PostImageByFileResponseSchemaV2 = await uploadImageByFile(
          input.acceptedFiles[0]
        );

        if (data.url.length === 0) {
          console.error("Image upload failed.");
          enqueueSnackbar("Image upload failed.", { variant: "error" });
          return;
        }

        put({
          params: {
            name: input.name,
            summary: input.description,
            website_url: input.webUrl,
            headquarters: input.headquarters,
            phone_number: input.phoneNumber,
            founded: input.founded,
            logo_url: data.url,
            twitter_url: input.twitter,
            facebook_url: input.facebook,
            tiktok_url: input.tiktok,
            youtube_url: input.youtube,
            instagram_url: input.instagram,
            linkedin_url: input.linkedin,
          },
        });
      } else {
        put({
          params: {
            name: input.name,
            summary: input.description,
            website_url: input.webUrl,
            headquarters: input.headquarters,
            phone_number: input.phoneNumber,
            founded: input.founded,
            logo_url: input.logoUrl,
            twitter_url: input.twitter,
            facebook_url: input.facebook,
            tiktok_url: input.tiktok,
            youtube_url: input.youtube,
            instagram_url: input.instagram,
            linkedin_url: input.linkedin,
          },
        });
      }
    } catch (e) {
      enqueueSnackbar(t("hooks.update_failed"), { variant: "error" });
    }
  };

  return { updateInfo };
};
